
// Import React Stuff
import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import GlobalContext from '../../context/GlobalContext.js';

// Import Select Styles
import { dDictArray } from '../../harddata/DataDictionary.js';
import { isNil } from '../../utils/ReshapeData.js';
import { allCompetitionIds } from '../../harddata/NcaaStructures.js';
import {
    blackSelectStyles,
    smallSelectStyles,
    filterSelectStyles,
    signupSelectStyles
} from '../../utils/ReactSelectStyles';


const createStringDate = (dateString) => {
    // dateString: 'YYYY-MM-DD' as string
    let year = dateString.substr(0, 4);
    let month = Number(dateString.substr(5, 2));
    let day = dateString.substr(8, 2);

    let months = { 1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' };
    let outputString = `${months[month]} ${day}, ${year}`;

    return outputString;
};
const createShortStringDate = (dateString) => {
    // dateString: 'YYYY-MM-DD' as string

    let year = dateString.substr(0, 4);
    let month = Number(dateString.substr(5, 2));
    let day = dateString.substr(8, 2);

    let months = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
    let outputString = `${months[month]} ${day}, ${year}`;

    return outputString;
};

const optionsDict = {
    axisStats: { // dataDictionary as options for axisStats
        1: { width: 148, options: dDictArray }
    },
    advFilterStats: { // dataDictionary as options for advFilterStats
        1: { width: 148, options: dDictArray }
    },
    gender: {
        1: { width: 175, options: [
            { value: 'MALE', label: `Men's Basketball` },
            { value: 'FEMALE', label: `Women's Basketball` }
        ] }
    },
    divisions: {
        1: { width: 110, options: [
            { value: 1, label: 'Division I' },
            { value: 2, label: 'Division II' },
            { value: 3, label: 'Division III' }
        ] },
        2: { width: 110, options: [
            { value: 1, label: 'Division I' },
            { value: 2, label: 'Division II' }
        ] }
    },
    boxOrPbp: {
        1: { width: 110, options: [
            { value: 'box', label: 'Box' },
            { value: 'pbp', label: 'PBP' }
        ] }
    },
    chartTypes: {
        1: { width: 130, options: [
            { value: 'hex', label: 'Heat Map' },
            { value: 'zone', label: 'Shot Zones' },
            { value: 'marker', label: 'Shot Markers' }
        ] },
        2: { width: 130, options: [
            { value: 'hex', label: 'Heat Map' },
            { value: 'zone', label: 'Shot Zones' }
        ] },
        3: { width: 130, options: [
            { value: 'zone', label: 'Shot Zones' },
            { value: 'marker', label: 'Shot Markers' }
        ] }
    },
    perWhatOptions: {
        1: { width: 148, options: [
            { value: '', label: 'Stat Totals' },
            { value: 'Pg', label: 'Stats / Game' },
            { value: 'P40', label: 'Stats / 40 Mins' },
            { value: 'P100', label: 'Stats / 100 Poss' }
        ] }
    },

    scopes: {
        1: { width: 200, options: [
            { value: 'l5g', label: 'Last 5 Games' },
            { value: 'l10g', label: 'Last 10 Games' },
            { value: 'season', label: 'Full Season' },
            { value: 'seasonAll', label: 'All Official Games' },
            { value: 'confReg', label: 'In-Conf (Conf Schedule)' },
            { value: 'confAll', label: 'In-Conf (All Matchups)' },
            { value: 'confWins', label: 'In-Conf (All) Wins' },
            { value: 'confLosses', label: 'In-Conf (All) Losses' },
            { value: 'nonconfReg', label: 'Non-Conf (Excl Postseason)' },
            { value: 'nonconfAll', label: 'Non-Conf (Inc Postseason)' },
            { value: 'post', label: 'Postseason' },
            { value: 'home', label: 'Home Games' },
            { value: 'away', label: 'Away Games' },
            { value: 'neutral', label: 'Neutral Games' },
            { value: 'wins', label: 'Games Won' },
            { value: 'losses', label: 'Games Lost' },
            { value: 'clutch', label: 'Clutch Stats' },
            { value: 'h1', label: '1st Half' },
            { value: 'h2', label: '2nd Half' },
            { value: 'quad1', label: 'Quad 1 Games' },
            { value: 'quad2', label: 'Quad 2 Games' },
            { value: 'quad3', label: 'Quad 3 Games' },
            { value: 'quad4', label: 'Quad 4 Games' },
            { value: 'daterange', label: 'Date Range' },
            { value: 'custom', label: 'Select Your Own Games' },
            { value: 'game', label: 'Single Game' },
            { value: 'ncaaTournament', label: 'NCAA Tournament' }
        ] }
    },

    tableTypes: {
        // 1. team game table types
        teamGameBox: { width: 225, options: [
            { value: 'fourfact', label: 'Team Four Factors', header: 'Team Four Factors' },
            { value: 'summary', label: 'Game Summaries', header: 'Game Summaries' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Advanced Defensive Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context Stats' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },
        teamGamePbp: { width: 245, options: [
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone Stats' },
            { value: 'astdUnastd', label: 'Shooting by Astd vs Unastd', header: 'Assisted & Unassisted Shooting' },
            { value: 'contextHcTrPb', label: 'HC, Transition, Putbacks', header: 'Half Court vs Transition vs Putbacks' },
            { value: 'pbpFouls', label: 'Foul Drawing Stats', header: 'Foul Drawing Stats' },
            { value: 'pace', label: 'Pace of Play, Time in Possession', header: 'Pace of Play, Time in Possession' }
        ] },

        // 2. player game table types
        playerGameBox: { width: 215, options: [
            { value: 'basic', label: 'Traditional Box Stats', header: 'Traditional Boxscore Stats' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Defensive Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context Stats' },
            { value: 'singleGameTweet', label: 'Narrow Summary', header: 'Game Breakdown' }, // primarily for single game tweets
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },
        playerGamePbp: { width: 215, options: [
            { value: 'tradshotPbp', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone Stats' },
            { value: 'assistregion', label: 'Assists by Zone', header: 'Assists by Zone' },
            { value: 'byShotClock', label: 'Shooting by Shot Clock', header: 'Shooting by Shot Clock' },
            { value: 'freeThrows', label: 'Free Throw Breakdown', header: 'Free Throw Breakdown' },
            { value: 'pbpFouls', label: 'Foul Drawing', header: 'Foul Drawing' },
            { value: 'playmaking', label: 'Playmaking, Assists', header: 'Playmaking & Assist Stats' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },

        // 3. team agg table types
        confTeamAggBox: { width: 215, options: [
            { value: 'fourfact', label: 'Team Four Factors', header: 'Team Four Factors' },
            { value: 'fourfactAdj', label: 'Team Four Factors (Adj)', header: 'Adjusted Team Four Factors' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'boxMargins', label: 'Boxscore Differentials', header: 'Team - Opponent Stats' },
            { value: 'offensive', label: 'Advanced Offense', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Advanced Defense', header: 'Advanced Defensive Stats' },
            { value: 'boxFouls', label: 'Foul Related', header: 'Foul Related Stats' },
            { value: 'miscScoring', label: 'Misc Team Scoring', header: 'Miscellanous Team Scoring Stats' },
            { value: 'records', label: 'Win/Loss by Splits', header: 'Win/Loss Record by Splits' },
            { value: 'leadDeficitWinLoss', label: 'Win/Loss by Lead/Deficit', header: 'Win/Loss Record by Lead/Deficit' }
        ] },
        divisionTeamAggBox: { width: 215, options: [
            { value: 'fourfact', label: 'Team Four Factors', header: 'Team Four Factors' },
            { value: 'fourfactAdj', label: 'Team Four Factors (Adj)', header: 'Adjusted Team Four Factors' },
            { value: 'fourfactAdjO', label: 'Team Four Factors (Adj O)', header: 'Adjusted Team Four Factors' },
            { value: 'fourfactAdjD', label: 'Team Four Factors (Adj D)', header: 'Adjusted Team Four Factors' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'boxMargins', label: 'Boxscore Differentials', header: 'Team - Opponent Stats' },
            { value: 'offensive', label: 'Advanced Offense', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Advanced Defense', header: 'Advanced Defensive Stats' },
            { value: 'boxFouls', label: 'Foul Related', header: 'Foul Related Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context' },
            { value: 'records', label: 'Win/Loss by Splits', header: 'Win/Loss Record by Splits' },
            { value: 'leadDeficitWinLoss', label: 'Win/Loss by Lead/Deficit', header: 'Win/Loss Record by Lead/Deficit' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },
        teamAggPbp: { width: 245, options: [ // For Conf & Division Team PBP Pages
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone' },
            { value: 'rebByZone', label: 'Rebounding by Shot Zone', header: 'Rebounding by Shot Zone' },
            { value: 'assistregion', label: 'Assists by Shot Zone', header: 'Assists by Shot Zone' },
            { value: 'byShotClock', label: 'Shooting by Shot Clock', header: 'Shooting by Shot Clock' },
            { value: 'fga3Pbp', label: 'Three Point Shooting', header: 'Three Point Shooting' },
            { value: 'contextHcTrPb', label: 'HC, Transition, Putbacks', header: 'Half Court vs Transition vs Putbacks' },
            { value: 'astdUnastd', label: 'Shooting by Astd vs Unastd', header: 'Assisted & Unassisted Shooting' }, // astd vs unastd
            { value: 'pbpFouls', label: 'Foul Drawing', header: 'Foul Drawing' },
            { value: 'rebByFgaFta', label: 'Rebounding on FGs vs FTs', header: 'Rebounding on FGs vs FTs' },
            { value: 'pace', label: 'Pace of Play, Time in Possession', header: 'Pace of Play, Time in Possession' },
            { value: 'freeThrows', label: 'Free Throw Breakdown', header: 'Free Throw Breakdown' },
            { value: 'tyingGoAhead', label: 'Game-Tying, Go-Ahead Shots', header: 'Game-Tying, Go-Ahead Shots' },
            { value: 'layupsDunks', label: 'Layups and Dunks', header: 'Layups and Dunks' },
            { value: 'turnoverType', label: 'Turnover Types', header: 'Turnover Types' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },

        // 4. player agg table types
        playerAgg: { width: 215, options: [
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Advanced Defensive Stats' },
            { value: 'boxFouls', label: 'Foul Related', header: 'Foul Related Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context Stats' },
            { value: 'allInOne', label: 'All-In-One Metrics', header: 'All In One Stats' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },
        playerAggPbp: { width: 245, options: [
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone' },
            { value: 'byShotClock', label: 'Shooting by Shot Clock', header: 'Shooting by Shot Clock' },
            { value: 'astdUnastd', label: 'Shooting by Astd vs Unastd', header: 'Assisted & Unassisted Shooting' }, // astd vs unastd
            { value: 'contextHcTrPb', label: 'HC, Transition, Putbacks', header: 'Half Court vs Transition vs Putbacks' },
            { value: 'pbpFouls', label: 'Foul Drawing', header: 'Foul Drawing' },
            { value: 'assistregion', label: 'Assists by Zone', header: 'Assists by Zone' },
            { value: 'playerUsage', label: 'Usage Breakdown', header: 'Player Usage Breakdown' },
            { value: 'fga3Pbp', label: 'Three Point Shooting', header: 'Three Point Shooting' },
            { value: 'rebByFgaFta', label: 'Rebounding by FG vs FT', header: 'Rebounding by FG vs FT' },
            { value: 'freeThrows', label: 'Free Throw Breakdown', header: 'Free Throw Breakdown' },
            { value: 'assisterScorer', label: 'Assister-Scorer Combos', header: 'Assister-Scorer Combos' },
            { value: 'tyingGoAhead', label: 'Game-Tying, Go-Ahead Shots', header: 'Game-Tying, Go-Ahead Shots' },
            { value: 'stlsBlksBy2s3s', label: 'Steals, Blocks by 2s vs 3s', header: 'Steals in/out the 3P Line, Blocks on 2PA/3PA' },
            { value: 'layupsDunks', label: 'Layups and Dunks', header: 'Layups and Dunks' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },

        // 5. miscellanous (lineups, of/off, combos, etc)
        lineupAgg: { width: 215, options: [
            { value: 'fourfact', label: 'Lineup Four Factors', header: 'Lineup Four Factors' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Advanced Defensive Stats' },
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone Stats' }
        ] },
        onOffAgg: { width: 215, options: [
            { value: 'fourfact', label: 'Team Four Factors', header: 'Team Four Factors' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Advanced Defensive Stats' },
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone Stats' }
        ] },
        conferenceAggStats: { width: 215, options: [
            { value: 'fourfact', label: 'Team Four Factors', header: 'Team Four Factors' },
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context' },
            { value: 'offensive', label: 'Advanced Offense', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Advanced Defense', header: 'Advanced Defensive Stats' }
        ] },


        shotChartsTeams: { width: 215, options: [
            { value: 'tradshotPbp', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone' }
        ] },
        shotChartsPlayers: { width: 215, options: [
            { value: 'tradshotPbp', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'shotregion', label: 'Shooting by Zone', header: 'Shooting by Zone' }
        ] },
        teamPlayerOnOffs: { width: 245, options: [
            { value: 'tradbox', label: 'Traditional Boxscore', header: 'Traditional Boxscore Stats' },
            { value: 'tradshot', label: 'Traditional Shooting', header: 'Traditional Shooting Stats' },
            { value: 'offensive', label: 'Offensive Stats', header: 'Advanced Offensive Stats' },
            { value: 'defensive', label: 'Defensive Stats', header: 'Advanced Defensive Stats' },
            { value: 'boxFouls', label: 'Foul Related', header: 'Foul Related Stats' },
            { value: 'miscScoring', label: 'Scoring Context', header: 'Scoring Context Stats' },
            { value: 'astdUnastd', label: 'Shooting by Astd vs Unastd', header: 'Assisted & Unassisted Shooting' },
            { value: 'diy', label: 'Select Your Own Columns', header: 'Custom Metrics Selected' }
        ] },
        streaksDroughts: { width: 175, options: [
            { value: 'teamStats', label: 'Team Statistics', header: 'Team Statistics' },
            { value: 'statDiffs', label: 'Stat Differentials', header: 'Stat Differentials' }
        ] }
    },

    pppcQualifiers: {
        chncStartType: { width: 215, options: [
            { value: 'oppFgm', label: 'Made Field Goal' },
            { value: 'oppFtm', label: 'Made Free Throw' },
            { value: 'drbFg', label: 'Defensive Rebound off FG' },
            { value: 'orbFg', label: 'Offensive Rebound off FG' },
            { value: 'drbFt', label: 'Defensive Rebound off FT' },
            { value: 'orbFt', label: 'Offensive Rebound off FT' },
            { value: 'oppTovLive', label: 'Live Ball Turnover' },
            { value: 'oppTovDead', label: 'Dead Ball Turnover' },
            { value: 'timeout', label: 'After Timeout' },
            { value: 'floorFoul', label: 'Floor Foul' },
            { value: 'periodStart', label: 'Period Start' },
            { value: 'other', label: 'Other' }
        ] },
        possStartType: { width: 215, options: [
            { value: 'oppFgm', label: 'Made Field Goal' },
            { value: 'oppFtm', label: 'Made Free Throw' },
            { value: 'drbFg', label: 'Defensive Rebound off FG' },
            { value: 'drbFt', label: 'Defensive Rebound off FT' },
            { value: 'oppTovLive', label: 'Live Ball Turnover' },
            { value: 'oppTovDead', label: 'Dead Ball Turnover' },
            { value: 'drbDead', label: 'Dead Ball Rebound' },
            { value: 'periodStart', label: 'Period Start' }
        ] },
        chncTime: { width: 160, options: [
            { value: 'secs0to10', label: '0 - 10 Secs' },
            { value: 'secs10to20', label: '10 - 20 Secs' },
            { value: 'secs20to30', label: '20 - 30 Secs' },
            { value: 'secs30plus', label: '30+ Secs' }
        ] },
        possTime: { width: 160, options: [
            { value: 'secs0to10', label: '0 - 10 Secs' },
            { value: 'secs10to20', label: '10 - 20 Secs' },
            { value: 'secs20to30', label: '20 - 30 Secs' },
            { value: 'secs30plus', label: '30+ Secs' }
        ] }
    },

    userFields: {
        adminPage: { width: 215, options: [
            { value: 'firstName', label: 'First Name' },
            { value: 'lastName', label: 'Last Name' },
            { value: 'fullName', label: 'Full Name' },
            { value: 'email', label: 'Email' },
            // { value: 'conference', label: 'Conference' },
            // { value: 'team', label: 'Team' },
            // { value: 'gender', label: 'Gender' },
            // { value: 'userType', label: 'User Type' },
            // { value: 'playerPctilesType', label: 'Player Percentiles Type' },
            // { value: 'shotChartColors', label: 'Shot Chart Colors' },
            { value: 'teamChangesRemaining', label: 'Team Changes Remaining' },
            // { value: 'tier', label: 'Tier' },
            // { value: 'tierTo', label: 'Tier To' },
            // { value: 'admin', label: 'Admin' },
            // { value: 'newsletter', label: 'Newsletter' },
            // { value: 'trialExpiresAt', label: 'Trial Expires At' },
            // { value: 'proTierExpiresAt', label: 'Pro Tier Expires At' },
            // { value: 'trialStartedAt', label: 'Trial Started At' },
            // { value: 'lastLoginAt', label: 'Last Login At' },
            { value: 'usedTrial', label: 'Used Trial' }, // handle boolean?
            { value: 'referralEmail', label: 'Referral Email' },
            { value: 'referralCode', label: 'Referral Code' },
            // { value: 'userAbilities', label: 'User Abilities' },
            // { value: 'notifications', label: 'Notifications' },
            { value: 'payments.stripeId', label: 'Payments > Stripe ID' },
            { value: 'payments.subscriptionId', label: 'Payments > Subscription ID' },
            { value: 'payments.canSubToProTier', label: 'Payments > Can Sub to Pro Tier' }
        ] }
    },

    sortDirection: {
        1: { width: 75, options: [
            { value: 'desc', label: 'Top', longName: 'Show highest values first' },
            { value: 'asc', label: 'Bottom', longName: 'Show lowest values first' }
        ] }
    },

    gameExplorer: {
        teamGameBox: { width: 183, options: [
            { value: '110Pts', label: '110 Points Scored' },
            { value: '504090', label: '50% FG, 40% 3P, 90% FT' },
            { value: '604090', label: '60% 2P, 40% 3P, 90% FT' },
            { value: '16Fgm3', label: '16 3s Made at 60% 3P%' },
            { value: '25Ftm', label: '25 FT Made at 90% FT%' },
            { value: '25Ast', label: '25 Assists at 80% AST%' }
        ] },
        playerGameBox: { width: 183, options: [
            { value: 'tripleDouble', label: 'Triple Doubles' },
            { value: '20Pts18Reb', label: '20 Points, 18 Rebounds' },
            { value: '20Pts10Ast', label: '20 Points, 10 Assists' },
            { value: '40Pts60FgPct', label: '40 Points on FG 60%' },
            { value: '10Ast1Tov', label: '10 Assists, 0-1 TOV' },
            { value: '4Blk4Stl', label: '4 Blocks, 4 Steals' },
            { value: '8Fgm360Pct', label: '8 3PM on 60% 3P%' },
            { value: '5x5', label: '5x5 (Pts, Reb, Ast, Stl, Blk)' },
            { value: '4x4', label: '4x4 (Pts, Reb, Ast, Stl, Blk)' },
            { value: 'interior', label: 'Interior (Reb, Blk, 2s)' }
        ] },
        teamGamePbp: { width: 183, options: [
            { value: 'teamsAtr2', label: '24 At Rim Made, ≥75% FG%' },
            { value: 'teamsAtb3', label: '15 Above Break 3s Made, ≥45% FG%' }
        ] },
        playerGamePbp: { width: 183, options: [
            { value: 'playersAtr2', label: '10 At Rim Made, ≥75% FG%' },
            { value: 'playersAtb3', label: '7 Above Break 3s Made, ≥50% FG%' }
        ] },
        teamPeriodBox: { width: 183, options: [
            { value: '504090', label: '50% FG, 40% 3P, 90% FT' },
            { value: '604090', label: '60% 2P, 40% 3P, 90% FT' }
        ] },
        playerPeriodBox: { width: 183, options: [] },
        teamPeriodPbp: { width: 183, options: [] },
        playerPeriodPbp: { width: 183, options: [] }
    },

    boxMarginsType: {
        1: { width: 175, options: [
            { value: 'allDiffs', label: 'All Differentials' },
            { value: 'shotsMade', label: 'Shots Made' },
            { value: 'shotsTaken', label: 'Shots Taken' },
            { value: 'possessions', label: 'Rebounds, Turnovers' }
        ] }
    },

    ratingsType: {
        1: { width: 133, options: [
            { value: 'pctiles', label: 'Division %iles' },
            { value: 'ranks', label: 'Division Ranks' }
        ] },
        2: { width: 133, options: [
            { value: 'pctiles', label: 'Division %iles' },
            { value: 'ranks', label: 'Division Ranks' },
            { value: 'ranksConf', label: 'Conf Ranks' }
        ] },
        3: { width: 118, options: [
            { value: 'pctiles', label: 'Percentiles' },
            { value: 'ranks', label: 'Ranks' }
        ] }
    },

    rollingGraphs: {
        1: { width: 190, options: [
            { value: 'gameBars', label: 'Game-Scale Bar Graph' },
            { value: 'dateBars', label: 'Date-Scale Bar Graph' },
            { value: 'gameLine', label: 'Game-Scale Line Graph' },
            { value: 'dateLine', label: 'Date-Scale Line Graph' }
        ] }
    },
    groupPppcBy: {
        1: {
            width: 160, options: [
                { value: 'team', label: 'Group by Team' },
                { value: 'qualifier', label: 'Group by Qualifier' }
            ]
        }
    },
    multiScopePairings: {
        1: {
            width: 200, options: [] // passed as optionsArray
        }
    },
    playerCompsGroup: {
        1: { width: 160, options: [
            { value: 'overall', label: 'Overall Stats' },
            { value: 'shooting', label: 'Shooting Stats' }
            // { value: 'diy', label: 'DIY Choose Stats' }
        ] }
    },
    playerCompsNumGraphs: {
        1: { width: 150, options: [
            { value: 4, label: 'Top 4 Comps' },
            { value: 12, label: 'Top 12 Comps' },
            { value: 40, label: 'Top 40 Comps' }
        ] }
    },
    onOffDiffOptions: {
        1: {
            width: 275, options: [
                { value: 'diff', label: 'Team Difference with player on/off' },
                { value: 'on', label: 'Team Stats with player on-court' },
                { value: 'off', label: 'Team Stats with player off-court' }
            ]
        }
    },
    shootingRowOptions: {
        1: {
            width: 130, options: [
                { value: 'fullRow', label: 'Shooting Row' },
                { value: 'singleChart', label: 'Single Chart' }
            ]
        }
    },
    gameCardsOrTable: {
        1: {
            width: 130, options: [
                { value: 'table', label: 'Table Layout' },
                { value: 'cards', label: 'Cards Layout' }
            ]
        }
    },
    dataSource: {
        1: { width: 220, options: [
            { value: 'cbbAnalyticsApi', label: 'Fetch from CBB Database' },
            { value: 'geniusRestApi', label: 'Fetch from NCAA Database' }
        ] }
    },
    multiSeason: {
        1: { width: 180, options: [
            { value: 'latest', label: 'Latest Player-Season' },
            { value: 'career', label: 'All Player-Seasons' }
        ] }
    },
    showTables: {
        1: { width: 135, options: [
            { value: true, label: 'Show Tables' },
            { value: false, label: 'Hide Tables' }
        ] }
    },
    confCardsOrTables: {
        1: { width: 175, options: [
            { value: 'cards', label: 'Conference Cards' },
            { value: 'tables', label: 'Conference Tables' }
        ] }
    },
    scopeType: {
        1: { width: 130, options: [
            { value: 'single', label: 'Single Split' },
            { value: 'multi', label: 'Multiple Splits' }
        ] }
    },
    layoutType: {
        1: { width: 165, options: [
            { value: 'separated', label: 'Separated Tables' },
            { value: 'combined', label: 'Combined Tables' }
        ] }
    },
    showHidePctiles: {
        1: { width: 165, options: [
            { value: 'show', label: 'Show Percentiles' },
            { value: 'hide', label: 'Hide Percentiles' }
        ] }
    },
    modelMpgMethod: {
        1: {
            width: 185, options: [ // Determine Model MPG based on (minutes per game, total minutes, blend)
                { value: 'minsPg', label: 'Minutes / Game' },
                { value: 'mins', label: 'Total Minutes' },
                { value: 'both', label: 'Blended (Avg of Both)' }
            ]
        }
    },
    rebByZoneTypes: {
        1: { width: 185, options: [
            { value: 'rebPcts', label: 'All Percentages' },
            { value: 'rebByZone', label: 'Overall Rebounding' },
            { value: 'orbByZone', label: 'Offensive Rebounding' },
            { value: 'drbByZone', label: 'Defensive Rebounding' }
        ] }
    },
    selectGameTracker: {
        1: {
            width: 95, options: [
                { value: 'circles', label: 'Circles' },
                { value: 'bars', label: 'Bars' }
            ]
        }
    },
    lineupFlowColorTypes: {
        1: {
            width: 110, options: [
                { value: 'teamHex', label: 'Team Color' },
                { value: 'netRtgScale', label: 'Net Rating' }
            ]
        },
        2: {
            width: 125, options: [
                { value: 'teamHex', label: 'Team Color' },
                { value: 'netRtgScale', label: 'Net Rating' },
                { value: 'playerColors', label: 'Color / Player' }
            ]
        }
    },
    homeAwayBoth: {
        1: {
            width: 160, options: [
                { value: 'home', label: 'Home Team' },
                { value: 'away', label: 'Away Team' },
                { value: 'both', label: 'Both Teams' }
            ]
        }
    },
    transferPortalPlayerStatus: {
        1: { width: 225, options: [
            { value: 'both', label: 'All (Available & Transferred)' },
            { value: 'transferred', label: 'Has Already Transferred' },
            { value: 'active', label: 'Still Available In Portal' }
        ] }
    },
    nilTransferScenario: {
        1: { width: 140, options: [
            { value: 'all', label: 'All Transfers' },
            { value: 'highToHigh', label: 'High to High' },
            { value: 'midToHigh', label: 'Mid to High' },
            { value: 'midToMid', label: 'Mid to Mid' }
        ] }
    },
    transferDirection: {
        1: { width: 250, options: [
            { value: 'both', label: 'Incoming & Outgoing Transfers' },
            { value: 'transfersIn', label: 'Incoming Transfers' },
            { value: 'transfersOut', label: 'Outgoing Transfers ' }
        ] }
    },
    transferSeasonFor: {
        1: { width: 250, options: [
            { value: 'both', label: 'Pre & Post Transfer Stats' },
            { value: 'priorYear', label: 'Pre Transfer (Prior Year) Stats' },
            { value: 'nextYear', label: 'Post Transfer (This Year) Stats' }
        ] }
    },
    transferLayout: {
        1: { width: 250, options: [
            { value: 'together', label: 'Players, Team Totals (Together)' },
            { value: 'separate', label: 'Players, Team Totals (Separate)' },
            { value: 'teamsOnly', label: 'Team Totals Only' },
            { value: 'playersOnly', label: 'Individual Players Only' },
            { value: 'byPlayer', label: 'Single Player Tables' }
        ] }
    },
    byPlayerOrSeasonGrouping: {
        1: { width: 175, options: [
            { value: 'player', label: 'Group by Player' },
            { value: 'season', label: 'Group by Season' }
        ] }
    },
    matchAnyAllFilters: {
        1: { width: 215, options: [
            { value: 'any', label: 'Match Any Search Filters' },
            { value: 'all', label: 'Match All Search Filters' }
        ] }
    },
    adjustCoreStats: {
        1: { width: 185, options: [
            { value: 'base', label: 'Core Stats Unadjusted' },
            { value: 'adj', label: 'Core Stats Adjusted' }
        ] }
    },
    scheduleShown: {
        1: {
            width: 215, options: [] // options passed as prop
        }
    },
    homeAway: {
        1: { width: 160, options: [
            { value: 'home', label: 'Home Team' },
            { value: 'away', label: 'Away Team' }
        ] }
    },
    shotChartPeriods: {
        1: { width: 140, options: [
            { value: 'all', label: 'Full Games' },
            { value: 'h1', label: '1st Half Only' },
            { value: 'h2', label: '2nd Half Only' }
        ] },
        2: { width: 150, options: [
            { value: 'all', label: 'Full Games' },
            { value: 'q1', label: '1st Quarter Only' },
            { value: 'q2', label: '2nd Quarter Only' },
            { value: 'q3', label: '3rd Quarter Only' },
            { value: 'q4', label: '4th Quarter Only' }
        ] }
    },
    shotChartMonths: {
        1: { width: 140, options: [
            { value: [11, 12, 1, 2, 3, 4], label: 'All Months' },
            { value: [11], label: 'November' },
            { value: [12], label: 'December' },
            { value: [1], label: 'January' },
            { value: [2], label: 'February' },
            { value: [3], label: 'March' }
        ] }
    },
    singleOrAllSeasons: {
        1: { width: 190, options: [
            { value: 'single', label: 'Single Season Comps' },
            { value: 'all', label: 'All Seasons Comps' }
        ] }
    },
    singleOrMultiSeason: {
        1: { width: 190, options: [
            { value: 'single', label: 'Single Season' },
            { value: 'multiple', label: 'Multiple Seasons' }
        ] }
    },
    maleFemale: {
        1: { width: 190, options: [
            { value: 'MALE', label: 'NCAA Mens' },
            { value: 'FEMALE', label: 'NCAA Womens' }
        ] }
    },
    hexOrMarker: {
        1: { width: 145, options: [
            { value: 'hex', label: 'Heat Map Graph' },
            { value: 'marker', label: 'Marker Graph' }
        ] }
    },
    hexMarkerZone: {
        1: { width: 145, options: [
            { value: 'hex', label: 'Heat Map Graph' },
            { value: 'zone', label: 'Zone Graph' },
            { value: 'marker', label: 'Marker Graph' }
        ] }
    },
    hexOrZones: {
        1: { width: 145, options: [
            { value: 'hex', label: 'Heat Map' },
            { value: 'fgPct', label: 'Zone (FG%)' },
            { value: 'fgaFreq', label: 'Zone (FGA%)' }
        ] }
    },
    numPlayersInLineup: {
        1: {
            width: 155, options: [
                { value: 5, label: '5-Player Combos' },
                { value: 4, label: '4-Player Combos' },
                { value: 3, label: '3-Player Combos' },
                { value: 2, label: '2-Player Combos' }
            ]
        }
    },
    teamOrOpponent: {
        1: { width: 155, options: [
            { value: 'team', label: 'Team Stats' },
            { value: 'opponent', label: 'Opponent Stats' }
        ] },
        2: { width: 200, options: [
            { value: 'team', label: 'Team Stats' },
            { value: 'opponent', label: 'Opponent Stats' },
            { value: 'both', label: 'Team & Opponent Stats' }
        ] }
    },
    possOrChnc: {
        1: { width: 155, options: [
            { value: 'poss', label: 'By Possession' },
            { value: 'chnc', label: 'By Chance' }
        ] }
    },
    coreTablesLayout: {
        1: { width: 155, options: [
            { value: 'together', label: 'Together' },
            { value: 'separate', label: 'Separate' }
        ] }
    },
    togetherSeparateLayout: {
        1: { width: 165, options: [
            { value: 'together', label: 'Combined Tables' },
            { value: 'separate', label: 'Separate Tables' }
        ] }
    },
    streaksTogetherSeparate: {
        1: { width: 215, options: [
            { value: 'together', label: 'Combined Tables for Runs' },
            { value: 'separate', label: 'Separate Tables for Runs' }
        ] }
    },
    pppcQualifierType: {
        1: { width: 155, options: [
            { value: 'startType', label: 'By Start Type' },
            { value: 'time', label: 'By Time' }
        ] }
    },
    isOffense: {
        1: { width: 145, options: [
            { value: true, label: 'Team Stats' },
            { value: false, label: 'Opponent Stats' }
        ] },
        2: { width: 168, options: [
            { value: true, label: 'Team Shooting' },
            { value: false, label: 'Opponent Shooting' }
        ] }
    },
    combinedRimPaint: {
        1: { width: 188, options: [
            { value: 'combined', label: 'Rim & Paint Combined' },
            { value: 'separate', label: 'Rim & Paint Separated' }
        ] }
    },
    byShotClockGrouping: {
        1: { width: 150, options: [
            { value: 'oneZone', label: 'Show for Zone' },
            { value: 'oneClock', label: 'Show for Clock' }
        ] }
    },
    zonesOrOverall: {
        1: { width: 155, options: [
            { value: 'overall', label: '2s, 3s, All Shots' },
            { value: 'zones', label: 'By Simple Zones' }
        ] }
    },
    simpleZones: {
        1: { width: 175, options: [
            { value: 'atr2', label: 'At The Rim' },
            { value: 'paint2', label: 'In The Paint 2s' },
            { value: 'mid2', label: 'Mid Range 2s' },
            { value: 'atb3', label: 'Above Break 3s' },
            { value: 'c3', label: 'Corner 3s' }
        ] }
    },
    simpleZones2s3sAll: {
        1: { width: 175, options: [
            { value: '2s', label: 'All 2-Pointers' },
            { value: '3s', label: 'All 3-Pointers' },
            { value: 'all', label: 'All Shots' },
            { value: 'atr2', label: 'At The Rim' },
            { value: 'paint2', label: 'In The Paint 2s' },
            { value: 'mid2', label: 'Mid Range 2s' },
            { value: 'atb3', label: 'Above Break 3s' },
            { value: 'c3', label: 'Corner 3s' }
        ] }
    },
    shotClockIntervals: {
        1: { width: 175, options: [
            { value: 'S01', label: '0-10 Seconds' },
            { value: 'S12', label: '10-20 Seconds' },
            { value: 'S23', label: '20-30 Seconds' }
        ] }
    },
    shotByZoneMetrics: {
        1: { width: 175, options: [
            { value: 'fgPct', label: 'FG Percentage' },
            { value: 'fgaFreqAll', label: 'FGA Frequency' }
        ] }
    },
    sortedMetricsToggle: {
        1: {
            width: 215, options: [
                { value: 'noSort', label: 'Standard Table Layout' },
                { value: 'tableSort', label: 'Each Table Sorted by %iles' },
                { value: 'allSort', label: 'All Metrics Sorted by %iles' }
            ]
        }
    },
    showFgmFga: {
        1: { width: 160, options: [
            { value: true, label: 'Show FGM/FGA' },
            { value: false, label: 'Hide FGM/FGA' }
        ] }
    },
    gameOrSeasonModelData: {
        1: { width: 225, options: [
            { value: 'game', label: 'Fit on Game Data' },
            { value: 'season', label: 'Fit on Season Data' }
        ] }
    },
    gameOrPeriod: {
        all3: { width: 148, options: [
            { value: 'game', label: 'Full Game Stats' },
            { value: 'half', label: 'Stats by Half' },
            { value: 'quarter', label: 'Stats by Quarter' }
        ] },
        halves: { width: 148, options: [
            { value: 'game', label: 'Full Game Stats' },
            { value: 'half', label: 'Stats by Half' }
        ] },
        quarters: { width: 148, options: [
            { value: 'game', label: 'Full Game Stats' },
            { value: 'quarter', label: 'Stats by Quarter' }
        ] }
    },

    tyingGoAhead: {
        1: { width: 225, options: [
            { value: 'tyingGoAhead', label: 'Game-Tying + Go-Ahead' },
            { value: 'tying', label: 'Game-Tying Shots' },
            { value: 'goAhead', label: 'Go-Ahead Shots' },
            { value: 'twosThrees', label: 'By Twos + Threes' }
        ] }
    },
    todayOrYesterday: {
        1: { width: 170, options: [
            { value: 'today', label: `Today's Games` },
            { value: 'yesterday', label: `Yesterday's Games` }
        ] }
    },
    setAdminTier: {
        1: { width: 300, options: [
            { value: 0, label: 'No Admin' },
            { value: 1, label: 'Lower Admin' },
            { value: 2, label: 'Full Admin' }
        ] }
    },
    setUserTier: {
        1: { // for nick on admin pages
            width: 300, options: [
                { value: 0, label: 'No Access: Not Verified' },
                { value: 1, label: 'Free Tier: Unlimited Free Access' },
                { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' },
                { value: 2, label: 'User Tier: Free Subscription', free: true },
                { value: 3, label: 'Pro Tier: D-I Teams Full Access (Trial)' },
                { value: 4, label: 'Pro Tier: D-I Teams Full Access' },
                { value: 4, label: 'Pro Tier: Free Subscription', free: true },
                { value: 5, label: 'Pro Tier: Super User Tier' },
                { value: 8, label: 'Lower Admin Tier' },
                { value: 9, label: 'Admin Tier' }
            ]
        },
        2: { // for users on signup page
            width: 300, options: [
                { value: 1, label: 'Free Tier: Unlimited Free Access' },
                { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' },
                { value: 4, label: 'Pro Tier: D-I Teams Full Access' }
            ]
        },
        3: { // for admin page, for lower admins
            width: 300, options: [
                { value: 0, label: 'No Access: Not Verified' },
                { value: 1, label: 'Free Tier: Unlimited Free Access' },
                { value: 2, label: 'User Tier: Free Subscription', free: true }
            ]
        },
        4: {
            width: 291, options: [
                { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' },
                { value: 4, label: 'Pro Tier: D-I Teams Full Access' }
            ]
        }
    },
    setUserType: {
        1: {
            width: 300, options: [
                { value: 'fan', label: 'Fan interested in basketball analytics' },
                { value: 'media', label: 'College basketball writer, journalist' },
                { value: 'team', label: 'Work for a college basketball team' },
                { value: 'nba', label: 'Work for an NBA team' },
                { value: 'other', label: 'Something else' }
            ]
        }
    },
    predefinedRegressionGroups: {
        1: { // 1 for offensive rating groups
            width: 180, options: [
                { value: 'fourfact', label: 'Four Factors' },
                { value: 'advancedBox', label: 'Box Score Stats' },
                { value: 'zones6FgPct', label: 'FG% By Shot Zone' },
                { value: 'zones6FgaFreq', label: 'FGA% By Shot Zone' },
                { value: 'scoringType', label: 'FG% By Scoring Type' }
            ]
        },
        2: { // 2 for defensive rating groups
            width: 250, options: [
                { value: 'oppoFourfact', label: 'Opponent Four Factors' },
                { value: 'defensiveStats', label: 'Team Defensive Stats' },
                { value: 'oppoAdvancedBox', label: 'Opponent Box Score Stats' },
                { value: 'oppoZones6FgPct', label: 'Opponent FG% By Shot Zone' },
                { value: 'oppoZones6FgaFreq', label: 'Opponent FGA% By Shot Zone' },
                { value: 'oppoScoringType', label: 'Opponent FG% By Scoring Type' }
            ]
        }
    },
    numPlayers: {
        1: {
            width: 155, options: [
                { value: 6, label: 'Top 6 Players' },
                { value: 7, label: 'Top 7 Players' },
                { value: 8, label: 'Top 8 Players' },
                { value: 9, label: 'Top 9 Players' },
                { value: 10, label: 'Top 10 Players' },
                { value: 11, label: 'Top 11 Players' },
                { value: 12, label: 'Top 12 Players' },
                { value: 100, label: 'Show All Players' }
            ]
        },
        2: {
            width: 155, options: [
                { value: 5, label: 'Show 5 Players' },
                { value: 6, label: 'Show 6 Players' },
                { value: 7, label: 'Show 7 Players' },
                { value: 8, label: 'Show 8 Players' },
                { value: 9, label: 'Show 9 Players' },
                { value: 10, label: 'Show 10 Players' }
            ]
        }
    },
    subscriptionPackages: {
        userTier: {
            width: 175, options: [
                { value: 30, label: '$30 / Month', text: '$30 / month (billed monthly)' },
                { value: 85, label: '$85 / 3 Months', text: '$85 / 3 months (billed once every 3 months)' },
                { value: 160, label: '$160 / 6 Months', text: '$160 / 6 months (billed once every 6 months)' },
                { value: 299, label: '$299 / 12 Months', text: '$299 / 12 months (billed once every 12 months)' }
            ]
        },
        proTier: {
            width: 175, options: [
                { value: 60, label: '$60 / Month', text: '$60 / month (billed monthly)' },
                { value: 600, label: '$600 / 12 Months', text: '$600 / 12 months (billed once every 12 months)' }
            ]
        }
    },
    playerPctileTypes: {
        1: { width: null, options: [
            { value: 'byPosition', label: 'Percentiles by player position (Guard, Forward)' },
            { value: 'allPlayers', label: 'Same percentiles for all players' }
        ] }
    },
    shotChartColors: {
        1: { width: null, options: [
            { value: 'blueToRed', label: 'Blue (Poor Shooting) to Red (Good Shooting)' },
            { value: 'redToGreen', label: 'Red (Poor Shooting) to Green (Good Shooting)' }
        ] }
    },
    shootingStatsFor: {
        1: {
            width: 155, options: [
                { value: 'full', label: 'Full Team' },
                { value: 'onOff', label: 'Players On/Off' },
                { value: 'players', label: 'Player Combos' }
            ]
        }
    },
    ptgcOptions: {
        1: {
            width: 100, options: [
                { value: 'player', label: 'Players' },
                { value: 'team', label: 'Teams' },
                { value: 'game', label: 'Games' }
            ]
        },
        2: {
            width: 200, options: [
                { value: 'team', label: 'Team Comparisons' },
                { value: 'player', label: 'Player Comparisons' }
            ]
        }
    },
    barChartOptions: {
        1: {
            width: 225, options: [
                { value: 'oneConf', label: 'Teams in One Conference' },
                { value: 'oneTeam', label: 'Players on One Team' },
                { value: 'oneGame', label: 'Players in One Game' },
                { value: 'topPlayers', label: 'Top N Players' },
                { value: 'topTeams', label: 'Top N Teams' },
                { value: 'sPlayers', label: 'Select Own Players' },
                { value: 'sTeams', label: 'Select Own Teams' },
                { value: 'oneMetricTeams', label: 'One Metric, Many Teams' },
                { value: 'oneMetricConfs', label: 'One Metric, Many Conferences' }
            ]
        }
    },
    scopePairings: {
        1: { width: 150, options: [
            { value: 'l5gFull', label: 'Last 5 vs. Season', scopes: ['season', 'l5g'] },
            { value: 'inconfNonconf', label: 'In vs. Non Conf', scopes: ['confReg', 'nonconfReg'] },
            { value: 'half1half2', label: '1st vs. 2nd Half', scopes: ['h1', 'h2'] },
            { value: 'homeAway', label: 'Home vs. Away', scopes: ['home', 'away'] },
            { value: 'winsLosses', label: 'Wins vs. Lossess', scopes: ['wins', 'losses'] },
            { value: 'quad1234', label: 'Quad 1, 2, 3, 4', scopes: ['quad1', 'quad2', 'quad3', 'quad4'] }
        ] }
    },
    gameTweets: {
        1: {
            width: 225, options: [
                { value: null, label: 'Select Graphic' },
                { value: 'tweet1', label: 'Teams Chart, Table' },
                { value: 'tweet2', label: 'Players Chart, Table' },
                { value: 'tweet3', label: 'Player Box Scores' }
            ]
        }
    },
    allInOneMetrics: {
        1: { width: 125, options: [
            { value: 'ws', label: 'Win Shares' },
            { value: 'rapm', label: 'CBB RAPM' }
        ] }
    },

    barChartColorStyles: {
        1: {
            width: 250, options: [
                { value: 'hex1hex2', label: 'Team Colors (1, 2)' },
                { value: 'hex2hex1', label: 'Team Colors (2, 1)' },
                { value: 'hex1gray', label: 'Team Color (1, Gray)' },
                { value: 'grayhex1', label: 'Team Color (Gray, 1)' },
                { value: 'hex2gray', label: 'Team Color (2, Gray)' },
                { value: 'grayhex2', label: 'Team Color (Gray, 2)' },
                { value: 'gray', label: 'Gray Scale' }
            ]
        }
    },
    zoneSchemas: {
        1: { width: 146, options: [
            { value: 'zones6', label: 'Simple Zones' },
            { value: 'zones13', label: 'Medium Zones' },
            { value: 'zones17', label: 'Complex Zones' },
            { value: 'dists7', label: 'Distance Zones' }
        ] },
        2: { width: 146, options: [
            { value: 'zones6', label: 'Simple Zones' },
            { value: 'dists7', label: 'Distance Zones' }
        ] }
    },
    zoneMetrics: {
        1: { width: 118, options: [
            { value: 'absFgPct', label: 'Total FG%' },
            { value: 'netFgPct', label: 'Net FG%' },
            { value: 'absEfgPct', label: 'Total eFG%' },
            { value: 'netEfgPct', label: 'Net eFG%' },
            { value: 'absFgaFreq', label: 'Total FGA%' },
            { value: 'netFgaFreq', label: 'Net FGA%' }
        ] },
        2: { width: 180, options: [
            { value: 'absFgPct', label: 'Total FG%, FGA%', fgPct: 'absFgPct', fgaFreq: 'absFgaFreq', fgPctLabel: 'Total FG%', fgaFreqLabel: 'Total % of Shots' },
            { value: 'netFgPct', label: 'Net FG%, FGA%', fgPct: 'netFgPct', fgaFreq: 'netFgaFreq', fgPctLabel: 'Net FG%', fgaFreqLabel: 'Net % of Shots' },
            { value: 'absEfgPct', label: 'Total eFG%, FGA%', fgPct: 'absEfgPct', fgaFreq: 'absFgaFreq', fgPctLabel: 'Total eFG%', fgaFreqLabel: 'Total % of Shots' },
            { value: 'netEfgPct', label: 'Net eFG%, FGA%', fgPct: 'netEfgPct', fgaFreq: 'netFgaFreq', fgPctLabel: 'Net eFG%', fgaFreqLabel: 'Net % of Shots' }
        ] },
        3: { width: 150, options: [ // for player shooting
            { value: 'none', label: 'No Third Metric' },
            { value: 'fga', label: 'Total FGAs' },
            { value: 'fgaPg', label: 'FGA / Game' },
            { value: 'fgaP40', label: 'FGA / 40 Mins ' }
        ] }
    },
    dbBracketDefinitions: {
        1: { width: 240,
            options: [] // options will be passed via optionsArray prop
        }
    },
    tournaments: {
        1: {
            width: 325,
            options: [] // options will be passed via optionsArray prop
        }
    },
    rounds: {
        1: {
            width: 325,
            options: [] // options will be passed via optionsArray prop
        }
    },
    views: {
        1: {
            width: 325,
            options: [] // options will be passed via optionsArray prop
        }
    },
    overviewStatsGroup: {
        1: { width: 180, options: [
            { value: 'fourfact', label: 'Four Factors' },
            { value: 'tradbox', label: 'Traditional Boxscores' },
            { value: 'tradshot', label: 'Traditional Shooting' },
            { value: 'control', label: 'Ball Handling' },
            { value: 'playstyle', label: 'Offensive Style' },
            { value: 'defensive', label: 'Defensive Stats' },
            { value: 'miscScoring', label: 'Scoring Type' }
        ] }
    },
    comparisonsAllFourUtil: {
        1: { width: 165, options: [
            { value: 'same', label: 'Same Years, Splits' },
            { value: 'fourYears', label: '4 Different Years' },
            { value: 'fourScopes', label: '4 Different Splits' }
        ] }
    },
    contextHcTrPb: {
        1: { width: 125, options: [
            { value: 'chncContextHcTrPb', label: 'Efficiency' },
            { value: 'shotContextHcTrPb', label: 'Shooting' }
        ] }
    },
    teamPlayersOnOffLayout: { // I can come up with 2 more layouts easily, another singlePlayer another multiPlayer.
        1: { width: 175, options: [
            { value: 'allPlayersView', label: 'All Players View' },
            { value: 'singlePlayerView', label: 'Single Player View' }
        ] },
        2: { width: 240, options: [ // subLayouts for 'singlePlayerView'
            { value: 'matchSeparate', label: 'Match Individual On & Off' },
            { value: 'allCombos', label: 'Show All On/Off Combos' }
        ] },
        3: { width: 175, options: [
            { value: 'bothSeparate', label: 'Both (Separate)' },
            { value: 'bothTogether', label: 'Both (Together)' },
            { value: 'teammatesOn', label: 'w/ Teammates On' },
            { value: 'teammatesOff', label: 'w/ Teammates Off' }
        ] },
        4: { width: 215, options: [
            { value: 'matchExact', label: 'Match Exact On & Off' },
            { value: 'allCombos', label: 'Show All On/Off Combos' }
        ] },
        5: { width: 160, options: [
            { value: 'byPlayer', label: 'Group by Player' },
            { value: 'byCombo', label: 'Group By Combo' }
        ] }
    },
    directionalOptions: {
        1: { width: 110, options: [
            { value: 'eq', label: '=', longName: `= equal` },
            { value: 'gt', label: '>', longName: '> greater than' },
            { value: 'gte', label: '≥', longName: '≥ greater than or equal' },
            { value: 'lt', label: '<', longName: '< less than' },
            { value: 'lte', label: '≤', longName: '≤ less than or equal' }
        ] }
    },
    sbzLgAvgTypes: {
        playersD1: { width: 170, options: [ // for D1 players, all the options
            { value: 'division', label: 'by Division' },
            { value: 'divisionG', label: 'by Division (Guards)' },
            { value: 'divisionF', label: 'by Division (Forwards)' },
            { value: 'conference', label: 'by Conference' },
            { value: 'highMajor', label: 'for High Majors' },
            { value: 'midMajor', label: 'for Mid Majors' },
            { value: 'lowMajor', label: 'for Low Majors' }
        ] },
        playersD230: { width: 170, options: [ // for D2,3,0 players, remove highMajor, midMajor, lowMajor
            { value: 'division', label: 'by Division' },
            { value: 'divisionG', label: 'by Division (Guards)' },
            { value: 'divisionF', label: 'by Division (Forwards)' },
            { value: 'conference', label: 'by Conference' }
        ] },
        teamsD1: { width: 150, options: [ // for D1 teams, remove by position
            { value: 'division', label: 'by Division' },
            { value: 'conference', label: 'by Conference' },
            { value: 'highMajor', label: 'for High Majors' },
            { value: 'midMajor', label: 'for Mid Majors' },
            { value: 'lowMajor', label: 'for Low Majors' }
        ] },
        teamsD230: { width: 150, options: [ // for D2,3,0 teams, remove highMajor, midMajor, lowMajor and by position
            { value: 'division', label: 'by Division' },
            { value: 'conference', label: 'by Conference' }
        ] }
    },
    includeExcludeZeros: {
        1: { width: 160, options: [
            { value: 'include', label: 'Include $0 Values' },
            { value: 'exclude', label: 'Exclude $0 Values' }
        ] }
    },
    allInOneOptions: {
        nilWarpWs: { width: 160, options: [
            { value: 'warpP40', label: 'WARP / 40' },
            { value: 'wsP40', label: 'Win Shares / 40' }
        ] },
        nilFour: { width: 120, options: [
            { value: 'rapm', label: 'RAPM' },
            { value: 'per', label: 'PER' },
            { value: 'warpP40', label: 'WARP / 40' },
            { value: 'wsP40', label: 'WS / 40' }
        ] },
        nilFourMedAvg: { width: 200, options: [
            { value: 'avg', label: 'Average Prediction' },
            { value: 'med', label: 'Median Prediction' },
            { value: 'rapm', label: 'RAPM Prediction' },
            { value: 'per', label: 'PER Prediction' },
            { value: 'warpP40', label: 'WARP / 40 Prediction' },
            { value: 'wsP40', label: 'WS / 40 Prediction' }
        ] }
    },

    gameInfos: { 1: { width: 325, options: [] } },
    players: { 1: { width: 225, options: [] } },
    teams: { 1: { width: 210, options: [] } },
    competitions: { 1: { width: 240 } },
    dates: { 1: { width: 165 } }
};


function CBBSelect({
    className = '', //              className for the select
    selectType, //                  for choosing options from the huge "optionsDict" above
    stylesType = 'default', //      main way to determine styles, loads from ReactSelectStyles
    value, //                       value of th. select
    setValue, //                    for setting value in parent component
    optionGroup = 1, //             sub groups for the selectType
    isDisabled = false, //          true/false to disable the select
    placeholder = 'Select...', //   placeholder text for the select
    disabledOptions = [], //        disable these options
    filterOptions = [], //          filter these option.values, keep the rest
    keeperOptions = [], //          keep these option.values, filter the rest
    wrapperStyle = {}, //           style for the wrapper div
    width = null, //                use to override width (set to 'none' to set width via parent)
    gender = null, //               filter for gender

    // handle custom optionArray, as well as custom getOptionValue(), getOptionLabel() functions
    optionsArray = null,
    valueFuncSS = null,
    labelFuncSS = null,

    // misc props needed for specific selectTypes
    dataSrcs = ['tas'], //      for "axisStats", "advFilterStats"
    ids = { array: [], competitionId: null, divisionId: null, conferenceId: null, tournamentIds: [] } // ids to utilize in filtering
}) {
    // console.log('CBBSelect Params: ', { selectType, value, setValue, optionGroup, optionsArray });

    // grab via useContext() for certain selectTypes
    let contextOptions = null;
    const { tournamentInfosObj } = selectType === 'scopes' ? useContext(GlobalContext) : { tournamentInfosObj: {} };
    const { teamInfosObj } = selectType === 'teams' ? useContext(GlobalContext) : { teamInfosObj: {} };
    const { competitionInfosObj } = selectType === 'competitions' ? useContext(GlobalContext) : { competitionInfosObj: {} };
    if (selectType === 'competitions') {
        contextOptions = Object.values(competitionInfosObj).map(row => { return { ...row, value: row.competitionId, label: row.competitionName }; });
    }

    // grab correct source of selects (fetch vs optionsArray vs optionsDict)
    const defaultOptions = optionsDict[selectType]?.[optionGroup]?.options || [];
    let selectOptions = optionsArray || contextOptions || defaultOptions;
    selectOptions = selectOptions
        .filter(option => filterOptions.length === 0 ? true : !filterOptions.includes(option.value))
        .filter(option => keeperOptions.length === 0 || selectType === 'scopePairings' ? true : keeperOptions.includes(option.value));

    // filters selectOptions for certain selectTypes
    switch (selectType) {
        // axisStats: filter for stat in tableType, for stat having labels 1,2,3, and no string/text units
        case 'axisStats':
            selectOptions = selectOptions
                .filter(stat => dataSrcs.some(dataSrc => stat.tables.includes(dataSrc)))
                .filter(stat => stat.label1 && stat.label2 && stat.label3)
                .filter(stat => stat.unit !== 'txt');
            break;

        // advFilterStats: filter for stat in tableType, for stat having labels 1,2,3, yes strings/text
        case 'advFilterStats':
            selectOptions = selectOptions
                .filter(stat => dataSrcs.some(dataSrc => stat.tables.includes(dataSrc)))
                .filter(stat => stat.label1 && stat.label2 && stat.label3);
            break;

        // competitionInfos: filter for competitions in data (2019+), sort and map to { value, label }
        case 'competitions':
            selectOptions = selectOptions
                .filter(row => gender === null ? true : row.gender === gender)
                .filter(row => allCompetitionIds.all.includes(row.competitionId))
                .sort((a, b) => a.endYear > b.endYear ? -1 : 1)
                .sort((a, b) => a.gender > b.gender ? -1 : 1)
                .map(row => { return { value: row.competitionId, label: row.competitionName }; });
            break;

        // teamInfos: filter for keeperTeams, divisionId, conferenceId
        case 'teams':
            selectOptions = (optionsArray ? (selectOptions || []) : (Object.values(teamInfosObj) || []))
                .filter(row => ids?.array?.length === 0 ? true : ids?.array?.includes(row.teamId)) // Use param to filter if not null
                .filter(row => isNil({ d: ids.divisionId }) ? true : row.divisionId === ids.divisionId) // Gives flexibility with
                .filter(row => isNil({ d: ids.conferenceId }) ? true : row.conferenceId === ids.conferenceId) // this component.
                .sort((a, b) => a.teamMarket.localeCompare(b.teamMarket));
            break;

        // dates: map to { value, label, shortLabel } and sort by value
        case 'dates':
            selectOptions = selectOptions
                .map(row => ({ value: row, label: createStringDate(row), shortLabel: createShortStringDate(row) }))
                .sort((a, b) => { return a.value > b.value ? 1 : -1; });
            break;

        // scopes: filter for keeperScopes, and handle tournaments scope
        case 'scopes':
            ids.tournamentIds.forEach(id => {
                let tourneyObj = tournamentInfosObj[id] ? tournamentInfosObj[id] : {};
                selectOptions.push({
                    value: `tourney${tourneyObj.tournamentId}`,
                    label: tourneyObj.tournamentName,
                    isTourney: true,
                    tournamentId: tourneyObj.tournamentId
                });
            }); break;

        // scopePairings: filter for pairings where all scopes in pairing are present in selectOptions
        case 'scopePairings':
            selectOptions = selectOptions.filter(row => {
                let keepOption = true;
                row.scopes.forEach(scope => {
                    if (!keeperOptions.includes(scope)) { keepOption = false; }
                });
                return keepOption;
            }); break;

        // default: do nada, no need to change selectOptions if not in case above
        default: // selectOptions = selectOptions;
    }

    // handle disabling options with { isDisabled: true }
    selectOptions = selectOptions.map(d => disabledOptions.includes(d.value) ? { ...d, isDisabled: true } : d);

    // create select using options defined above
    let selectStyles;
    switch (stylesType) {
        case 'default': selectStyles = blackSelectStyles(); break;
        case 'small': selectStyles = smallSelectStyles; break;
        case 'filter': selectStyles = filterSelectStyles; break;
        case 'signup': selectStyles = signupSelectStyles; break;
        default: selectStyles = blackSelectStyles();
    }

    // create getOptionValue, getOptionLabel functions
    let valueFunc = null, labelFunc = null;
    switch (selectType) {
        case 'axisStats': valueFunc = d => d.key; labelFunc = d => d.label3; break;
        case 'advFilterStats': valueFunc = d => d.key; labelFunc = d => d.label3; break;
        case 'teams': valueFunc = d => d.teamId; labelFunc = d => `${d.teamMarket} ${d.teamName ? d.teamName : ''}`; break;
        case 'players': valueFunc = d => d.playerId; labelFunc = d => `${d.fullName}`; break;
        default: valueFunc = d => d.value; labelFunc = d => d.label;
    }
    // handle custom superseded valueFunc, labelFunc passed as props
    valueFunc = valueFuncSS ? valueFuncSS : valueFunc;
    labelFunc = labelFuncSS ? labelFuncSS : labelFunc;

    const CustomOption = (props) => {
        // console.log('props: ', { props, labelFunc });

        // regex pattern matching on **
        const pattern = /\*\*([A-Za-z]+)/;
        let mainLabel = typeof labelFunc === 'function'
            ? labelFunc(props.data)
            : (props?.data?.label || '');

        // early exit if main label is not a string
        if (typeof mainLabel !== 'string') {
            return (
                <components.Option {...props}>
                    {mainLabel}
                </components.Option>
            );
        }

        // Variables to hold the processed label parts
        const match = mainLabel.match(pattern);
        let superScriptContent = null;

        // If match, split label to exclude the matched pattern, and create JSX <sup> for the superscript
        if (match) {
            mainLabel = mainLabel.replace(pattern, '');
            superScriptContent = <sup>{match[1]}</sup>;
        }

        // and return
        return (
            <components.Option {...props}>
                {mainLabel}{superScriptContent}
            </components.Option>
        );
    };

    // Final items before building select
    const selectTypeClassName = selectType ? selectType.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : 'nada-select-type'; // "/([a-z])([A-Z])/g" matches any lowercase letter followed by an uppercase letter and replaces it with the matched lowercase letter followed by a dash and the matched uppercase letter. Finally, use toLowerCase() method to convert the string to lowercase.
    const finalValue = value && Object.keys(value).length > 0 ? value : null; // replace empty objects with null (so placeholder shows)

    // Build Select Component
    const thisSelect =
        (<Select
            className={`cbb-select cbb-${selectTypeClassName}-select ${className}`}
            classNames={{ option: d => `cbb-option-${valueFunc(d.data)}` }}
            styles={selectStyles}
            isDisabled={isDisabled}
            value={finalValue}
            placeholder={placeholder} // could add fallback placeholder for each selectType (e.g. placeholder || optionsDict[selectType].placeholder)
            onChange={e => setValue(e)}
            options={selectOptions}
            {...(typeof valueFunc === 'function' && { getOptionValue: valueFunc })}
            {...(typeof labelFunc === 'function' && { getOptionLabel: labelFunc })}
            components={{
                Option: CustomOption
            }}
        />);

    // grab width
    if (width !== 'none' && !width && !optionsDict[selectType][optionGroup]) { console.log('error incoming for:', { selectType, optionGroup }); }
    let selectWidth = width === 'none' ? null : (width || optionsDict[selectType][optionGroup].width);
    if (stylesType === 'small') { selectWidth = 0.89 * selectWidth; }

    // and return
    return (
        <div style={{ width: selectWidth, marginRight: 3, marginTop: 3, ...wrapperStyle }}>
            {thisSelect}
        </div>
    );
}

export default CBBSelect;
